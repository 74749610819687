import Vue from "vue";
import Router from "vue-router";
import AuthLayout from "bh-mod/layouts/Auth";
import BHLayout from "bh-mod/layouts/BH";
import Splash from "@/layouts/Splash";
import AppLayout from "bh-mod/layouts/App/admin";
import PrivacyLayout from "@/layouts/Privacy/privacy";
import store from "@/store";
import { $api, $auth } from "bh-mod";
import { isLogged, getCookie, setCookie } from "bh-mod";

Vue.use(Router);

const router = new Router({
	base: process.env.BASE_URL,
	mode: "history",
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "/",
			redirect: "/apps",
			component: BHLayout,
			meta: { authRequired: true, hidden: true },
			children: [
				// Dashboards
				{
					path: "/select",
					meta: {
						title: "Select Project",
						isInstanceSelect: true,
					},
					component: () => import("./views/select"),
				},
				{
					path: "/select/:instanceID",
					meta: {
						title: "Select Project",
						selectedInstance: true,
					},
					props: true,
					component: () => import("./views/select/instance"),
				},
				{
					path: "/setup",
					component: Splash,
					meta: { hidden: true, splash: true },
					children: [
						{
							path: "/setup",
							meta: {
								title: "Setup Project",
								splashScreen: true,
								switchInstance: true,
								hidden: true,
							},
							component: () => import("./views/setup"),
						},
					],
				}
			],
		},
		{
			path: "/",
			redirect: "/apps",
			component: AppLayout,
			meta: { authRequired: true, hidden: true },
			children: [
				// Dashboards
				{
					path: "/instance/create",
					meta: {
						title: "Create Project",
					},
					component: () => import("./views/instance/create"),
				},
				{
					path: "/apps",
					meta: {
						title: "Apps",
						isInstanceHome: true,
						switchInstance: true,
						sidebar: false,
					},
					component: () => import("./views/apps"),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						switchInstance: true,
						sidebar: false,
					},
					component: () =>
						import("bh-mod/components/layout/TopBar/Actions/notifications.vue"),
				},
			],
		},

		{
			path: "/settings",
			redirect: "/settings/accounts",
			component: AppLayout,
			meta: { authRequired: true, hidden: true },
			children: [
				{
					path: "/settings/accounts",
					meta: {
						title: "Account Profile",
						sidebar: true,
						simpleTopbar: true,
					},
					component: () => import("./views/settings/accounts"),
				},
				{
					path: "/settings/projects",
					meta: {
						title: "Account Projects",
						sidebar: true,
						simpleTopbar: true,
					},
					component: () => import("./views/settings/projects"),
				},
				{
					path: "/settings/projects/edit",
					meta: {
						title: "Account Projects",
						sidebar: true,
						simpleTopbar: true,
					},
					component: () => import("./views/settings/editinstance"),
				},
				{
					path: "/settings/billing",
					meta: {
						title: "Billings & Payment",
						sidebar: true,
						simpleTopbar: true,
					},
					component: () => import("./views/settings/billing"),
				},
				{
					path: "/settings/billing/invoice/:id",
					meta: {
						title: "Billings & Payment",
						sidebar: true,
						simpleTopbar: true,
					},
					component: () => import("./views/settings/invoice"),
				}
			],
		},

		// System Pages
		{
			path: "/system",
			component: AuthLayout,
			redirect: "/system/login",
			children: [
				{
					path: "/system/login",
					meta: {
						title: "Login",
						redirectAuth: true,
					},
					component: () => import("./views/system/login"),
				},
				{
					path: "/system/reset-password",
					meta: {
						title: "Set Up Password",
						redirectAuth: true,
					},
					component: () => import("./views/system/reset"),
				},
				{
					path: "/system/forgot-password",
					meta: {
						title: "Forgot Password",
						redirectAuth: true,
					},
					component: () => import("./views/system/forgot-password"),
				},
				{
					path: "/system/register",
					meta: {
						title: "Register",
						redirectAuth: true,
					},
					component: () => import("./views/system/register"),
				},
				{
					path: "/system/register/agent/:token",
					meta: {
						title: "Register",
						redirectAuth: true,
					},
					component: () => import("./views/system/register/agent"),
				},
				{
					path: "/system/reservation/:id",
					meta: {
						title: "Reservation Fee",
						redirectAuthTermsPrivacy: true,
					},
					component: () => import("./views/system/reservation"),
				},
			],
		},

		//Microsoft Single Sign On
		{
			path: "/auth/microsoft/callback",
			component: AuthLayout,
			children: [
				{
					path: "/auth/microsoft/callback",
					meta: {
						title: "Microsoft SSO",
						redirectAuth: true,
						ssoCallback: true
					},
					component: () => import("./views/system/login"),
				},
			],
		},

		//Google Single Sign On
		{
			path: "/auth/google/callback",
			component: AuthLayout,
			children: [
				{
					path: "/auth/google/callback",
					meta: {
						title: "Google SSO",
						redirectAuth: true,
						ssoCallback: true
					},
					component: () => import("./views/system/login"),
				},
			],
		},

		//Agent registration
		{
			path: "/register",
			component: AuthLayout,
			children: [
				{
					path: "/register/agent/:token",
					meta: {
						title: "Register",
						redirectAuth: true,
					},
					component: () => import("./views/system/register/agent"),
				},
			],
		},

		// Privacy Policy, Terms and Conditions, support
		{
			path: "/system",
			component: PrivacyLayout,
			children: [
				{
					path: "/system/terms",
					meta: {
						title: "Terms and Conditions",
						redirectAuthTermsPrivacy: true,
					},
					component: () => import("./views/system/legals"),
				},
			],
		},
		{
			path: "/system",
			component: PrivacyLayout,
			children: [
				{
					path: "/system/privacy",
					meta: {
						title: "Privacy Policy",
						redirectAuthTermsPrivacy: true,
					},
					component: () => import("./views/system/legals"),
				},
			],
		},
		{
			path: "/system",
			component: PrivacyLayout,
			children: [
				{
					path: "/system/support",
					meta: {
						title: "Support",
						redirectAuthTermsPrivacy: true,
					},
					component: () => import("./views/system/legals"),
				},
			],
		},
		{
			path: '/404',
			component: AuthLayout,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
});

router.beforeEach(async (to, from, next) => {
	// Extract meta properties from matched routes
	const {
		authRequired,
		redirectAuth,
		ssoCallback,
		selectedInstance,
		isInstanceHome,
		isInstanceSelect,
		redirectAuthTermsPrivacy
	} = to.matched.reduce((meta, record) => {
		Object.keys(record.meta).forEach(key => meta[key] = record.meta[key]);
		return meta;
	}, {});

	// Skip navigation if route specifically requires bypassing
	if (redirectAuthTermsPrivacy) return next();

	// Handle URL parameters related to cookies
	handleUrlParams();

	// Special handling for SSO callback routes
	if (ssoCallback) return handleSSOCallback(to, next);

	// Reset instance data when navigating to instance selection page
	if (isInstanceSelect) resetInstance();

	// Retrieve JWT token from cookies for further authentication checks
	const jwt = getCookie("__bhjt");

	// Check for redirect if user is authenticated
	if (redirectAuth && jwt) {
		if (await isLogged(jwt, store)) return next("/select");
		return next();
	}

	// Handle routes requiring authentication
	if (authRequired) {
		if (!(await validateUser(jwt))) return redirectToLogin(to, next);

		// Proceed with instance selection if applicable
		if (await handleInstanceSelection(to, selectedInstance, isInstanceHome, next)) return;

		// Proceed with the final route determination
		return finalRouter(to, next);
	}

	// Default behavior for routes not requiring authentication
	return next();
});

// Utility function to manage URL parameters and set cookies appropriately
function handleUrlParams() {
	const urlParams = new URLSearchParams(location.search);
	["__bhr", "__bhjt", "__bhi"].forEach(key => {
		if (urlParams.get(key)) setCookie(key, urlParams.get(key), key === '__bhjt' ? null : '15');
	});
}

// Handle Single Sign-On (SSO) callback functionality
async function handleSSOCallback(to, next) {
	try {
		const { data } = await $auth.get(to.fullPath);
		if (await isLogged(data.jwt, store)) return next("/select");
		return next();
	} catch (error) {
		console.error('Error during SSO callback', error);
		return next("/select");
	}
}

// Function to reset instance-related data in cookies and store
function resetInstance() {
	setCookie("instance", "");
	store.commit("CLEAR_INSTANCE");
}

// Validate user state based on JWT token and current store state
async function validateUser(jwt) {
	if (!store.state.user.user && jwt) {
		return await isLogged(jwt, store);
	}
	return !!store.state.user.user;
}

// Redirect user to login page if not authenticated
function redirectToLogin(to, next) {
	return next({
		path: "/system/login",
		query: { redirect: to.fullPath },
	});
}

// Handle logic for selecting or loading instances
async function handleInstanceSelection(to, selectedInstance, isInstanceHome, next) {
	try {
		await store.dispatch("getInstances");

		if (selectedInstance && to.params.instanceID) {
			const result = await loadInstanceDetails(to.params.instanceID);
			return result ? next(result) : finalRouter(to, next);
		}

		if (isInstanceHome) {
			const cookieInstance = getCookie("instance");
			if (!store.state.instance) {
				if (!cookieInstance) {
					return next("/select");
				} else {
					return next(`/select/${cookieInstance}/`);
				}
			}
		}
	} catch (error) {
		console.error('Error handling instance selection', error);
	}
	return false;
}

// Load details for a specific instance, setting state and cookies as needed
async function loadInstanceDetails(instanceID) {
	store.commit("LOAD", true)
	try {
		const { data: instanceDetails } = await $api.get(`/instances/${instanceID}?hive=h`);
		if (instanceDetails.id) {
			store.commit("SET_INSTANCE", instanceDetails);
			setCookie("instance", instanceID); // No expiry argument to match original behavior
			Vue.prototype.$userType = instanceDetails.isAgentUser ? 'agent' : 'user';
			const { data } = await $api.get(`/settings/${instanceID}`);
			store.commit("SET_SETTINGS", data);
			if (data.userInstance == null || !data.userInstance.options) {
				return "/setup"; // Return the path to be used by the calling function
			}
			return null
		}
	} catch (error) {
		console.error('Error loading instance details', error);
	} finally {
		store.commit("LOAD", false)
	}
	return "/select"; // Default path if no specific conditions are met
}

async function finalRouter(to, next) {
	if (
		Vue.prototype.$userType === "agent" &&
		(to.path.startsWith("/instance/create") ||
			to.path.startsWith("/settings/projects") ||
			to.path.startsWith("/settings/billing"))
	) {
		return next("/select");
	}
	return next();
}

export default router;
